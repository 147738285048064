<template>
  <div class="jobContainer">
    <OpenPositionTemplate />

    <div class="container appProcessContainer">
      <SectionHeader
        titleLeft="Application"
        titleRight="Process"
        description="Our application process is designed to find talent for our dynamic IT environment. Begin with an online application to showcase your qualifications"
      />
      <div>
        <div class="card-group d-none d-xl-block">
          <div class="row">
            <div class="application-process-bg">
              <lottie-vue-player
                :src="JSON.stringify(lottie_json)"
                :autoplay="true"
                :loop="true"
                :speed="0.4"
              >
              </lottie-vue-player>
            </div>
            <div
              class="col"
              v-for="(values, index) in newProcessList"
              :key="index"
            >
              <ApplicationProcessCard
                :imageid="index + 1"
                :title_pro="values.title"
                :description_pro="values.description"
              />
            </div>
          </div>
        </div>

        <div class="d-block d-xl-none">
          <div class="row g-3">
            <div
              class="col-sm-12"
              v-for="(values, index) in newProcessList"
              :key="index"
            >
              <ApplicationProcessCard
                :imageid="index + 1"
                :title_pro="values.title"
                :description_pro="values.description"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="career">
      <CareerOptionsContainer />
    </div>

    <div class="container ourCoreValuesContainer">
      <SectionHeader
        titleLeft="Our Core "
        titleRight="Values"
        description="Our core values are the guiding principles that underpin our work and define our culture. Integrity is at the heart of everything we do; we believe in honesty, transparency, and ethical behavior."
      />

      <div>
        <div class="row gx-3">
          <div
            class="col-sm-3 d-none d-xl-block"
            v-for="(values, index) in coreValues"
            :key="index"
          >
            <div :class="[(index + 1) % 2 === 0 && 'cardStyle']">
              <CoreValueCard
                :borderColor="values.borderColor"
                :title="values.title"
                :description="values.description"
              />
            </div>
          </div>
        </div>
        <div class="row g-3">
          <div
            class="col-6 col-md-6 d-block d-xl-none"
            v-for="(values, index) in coreValues"
            :key="index"
          >
            <CoreValueCard
              :borderColor="values.borderColor"
              :title="values.title"
              :description="values.description"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="lifeAtMWContainer">
      <div class="container pb-5">
        <SectionHeader
          titleLeft="Life At"
          titleRight="Mediusware"
          description="Our employees thrives on solving complex challenges, embracing new technologies, and pushing the boundaries of what's possible in the software world."
        />
        <!-- dynamicColor="#757E95" -->

        <div class="row">
          <div class="col-12 cs-grid">
            <div
              class="mb-3 mb-sm-4 mb-lg-5"
              v-for="(values, index) in 6"
              :key="index"
            >
              <img
                :src="
                  '../../../assets/lifeAtMediusware/compressed-lifeat' +
                  (index + 1) +
                  '.jpg'
                "
                alt=""
                class="w-100"
              />
            </div>
          </div>
        </div>

        <!-- caoursel -->
        <div>
          <!-- <carousel-3d :perspective="0" :space="400" :display="3">
            <slide
              :index="0"
              class="d-flex justify-content-center align-items-center"
            >
              Slide 1 Content
            </slide>

            <slide
              :index="1"
              class="d-flex justify-content-center align-items-center"
            >
              Slide 2 Content
            </slide>
            <slide
              :index="2"
              class="d-flex justify-content-center align-items-center"
            >
              Slide 3 Content
            </slide>
          </carousel-3d> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import LottieAnimation from "lottie-web-vue"
import Animationlayer from "./Animationlayer2.json";
import SectionHeader from "../../../components/SectionHeader.vue";
import CoreValueCard from "../../../components/CoreValueCard.vue";
import OpenPositionTemplate from "../../../components/OpenPositionTemplate.vue";
import ApplicationProcessCard from "../../../components/ApplicationProcessCard.vue";
import CareerOptionsContainer from "../../../components/CareerOptionsContainer.vue";

export default {
  name: "NewJob",
  components: {
    OpenPositionTemplate,
    SectionHeader,
    CoreValueCard,
    ApplicationProcessCard,
    CareerOptionsContainer,
  },
  data: () => ({
    lottie_json: Animationlayer,
    newProcessList: [
      {
        title: "Application review",
        description:
          "We evaluate applications using multiple criteria to find the best candidates.",
      },
      {
        title: "Quick test",
        description: "We conduct online interviews to get to know you better.",
      },
      {
        title: "Exam",
        description:
          "Finalists participate in a one-day onsite challenge for the final judge.",
      },
      {
        title: "Interviews",
        description:
          "Team leads are conducting this interview to recruit new hires.",
      },
      {
        title: "Offer",
        description:
          "After a successful interview, you'll get a job offer at Mediusware.",
      },
    ],
    coreValues: [
      {
        title: "Team work",
        description:
          "Our team works together to achieve common goals and supports each other to ensure the success of our projects.",
        borderColor: "#9483FA",
      },
      {
        title: "Secured Future",
        description:
          "We recognize the significance of a stable future, and our commitment lies in furnishing our staff with a secure and nurturing workplace.",
        borderColor: "#E5576D",
      },
      {
        title: "Upgrade Skills",
        description:
          "At our company, we believe in staying ahead of the curve and embracing new technologies and techniques.",
        borderColor: "#3ADACA",
      },
      {
        title: "Delicious Foods",
        description:
          "We are providing unlimited coffee, free lunch, Snacks so that you can enjoy it with everyone.",
        borderColor: "#EAB308",
      },
      {
        title: "Annual Retreat",
        description:
          "Our annual retreats offer a mix of workshops, outdoor activities, and social events in a scenic setting that fosters creativity and camaraderie.",
        borderColor: "#FA83E0",
      },
      {
        title: "Learning Opportunity",
        description:
          "We value continuous learning and development, and we offer a range of opportunities for our employees to grow their skills and expand their knowledge.",
        borderColor: "#6ED8FC",
      },
      {
        title: "Weekly Event",
        description:
          "Every week various events are organized in our company so that the employees are excited.",
        borderColor: "#64DA3A",
      },
      {
        title: "Paid holiday leave",
        description:
          "To respect the diversity of our teams, we offer a total of 21 days paid leave.",
        borderColor: "#517EE1",
      },
    ],

    slideList: [
      {
        img: "https://picsum.photos/800/300/?image=1",
      },

      {
        img: "https://picsum.photos/800/300/?image=1",
      },
    ],
  }),
};
</script>

<style scoped lang="scss">
.jobContainer {
  .appProcessContainer {
    .card-group {
      position: relative;

      .application-process-bg {
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
      }
    }
  }

  .ourCoreValuesContainer {
    .cardStyle {
      margin-top: 24px;
    }
  }

  .cs-grid {
    display: grid;
    grid-template-columns: 1fr 493px 1fr;
  }

  @media (max-width: 1399px) {
    .cs-grid {
      display: grid;
      grid-template-columns: 1fr 424px 1fr;
    }
  }

  @media (max-width: 1199px) {
    .cs-grid {
      display: grid;
      grid-template-columns: 1fr 356px 1fr;
    }
  }

  @media (max-width: 991px) {
    .cs-grid {
      display: grid;
      grid-template-columns: 1fr 265px 1fr;
    }
  }

  @media (max-width: 768px) {
    .cs-grid {
      display: grid;
      grid-template-columns: 1fr 197px 1fr;
    }
  }

  @media (max-width: 575px) {
    .cs-grid {
      display: grid;
      grid-template-columns: 1fr 163px 1fr;
    }
  }

  @media (max-width: 399px) {
    .cs-grid {
      display: grid;
      grid-template-columns: 1fr 136px 1fr;
    }
  }
}
</style>
