import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import axios from "axios";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        activeUser: '',
        token: ''
    },
    plugins: [createPersistedState()],
    mutations: {
        UPDATE_USER(state, payload) {
            state.activeUser = payload
        },
        SET_TOKEN(state, payload) {
            state.token = payload
        }
    },
    actions: {
        fetchActiveUser(context) {
            axios.get('/candidate/', {headers: {'Authorization': `Bareer ${localStorage.getItem('_token')}`}})
                .then(res => {
                    context.commit('UPDATE_USER', res.data)
                }).catch(err => {
                context.commit('UPDATE_USER', '')
            })
        },

        clearState(context) {
            context.commit('SET_TOKEN', '')
            context.commit('UPDATE_USER', '')
        }
    }
})
